.charts-morris-page {
  .lp-nav {
    position: relative;
    padding: 30px 20px 0;
    @media (max-width: 576px) {
      background-color: #fcfcff;
      width: 100%;
      height: 46px;
      overflow: hidden;
      padding: 0 20px;
    }
    @media (min-width: 1200px) {
      padding: 30px 60px 0;
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    border-bottom: 1px solid #e7e7e7;
    @media (max-width: 768px) {
      border-bottom: 0;
      margin: 0;
      padding-top: 19px;
    }
  }

  .nav-item {
    position: relative;
    padding-right: 39px;
  }

  .compare-by {
    display: inline;
    @media (max-width: 576px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .col-md-6.chart-wrapper {
      padding: 0;
    }
  }
}
