.fixed-tabs {
  .lp-nav {
    position: relative;
    padding: 30px 20px 0;

    .nav {
      border-bottom: 1px solid #e7e7e7;
    }

    .nav-item {
      padding-right: 39px;
      margin: 0;

      .nav-link {
        padding-bottom: 5px;
        border-radius: 0;
        font-size: 15px;
        color: #6C7488;
        cursor: pointer;

        &.active {
          border-bottom: 3px var(--lp-colors-medium-blue-600) solid;
          background-color: transparent;
          color: #303639;
        }
      }
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
  }

  .nav-item {
    position: relative;
  }

  @media (min-width: 1200px) {
    .lp-nav {
      padding: 16px 60px 11px;
    }
  }

  @media (max-width: 768px) {
    .lp-nav .nav {
      border-bottom: 0;
      margin: 0;
    }
  }

  @media (max-width: 570px) {
    .lp-nav {
      background-color: #fcfcfc;
      position: relative;
      width: 100%;
      height: 46px;
      overflow: hidden;
      white-space: nowrap;

      ul {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      .nav-item {
        padding-right: 21px;
      }

      li {
        float: left;
      }
    }
  }
}