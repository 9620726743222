.api-keys-page {
  .media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .wicons {
    border: 1px solid #989FB2;
    width: 36px;
    height: 36px;
  }

  .feature-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }

  .media-body {
    -ms-flex: 1;
    flex: 1;
  }

  .card {
    background-color: transparent;
    margin-bottom: 10px;
    border-radius: 0;
    border: none;
    padding: 0 7px;
  }

  .item-list {
    list-style: none;
    margin: 0;
    margin-bottom: 0;
    line-height: 21px;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;

    &.striped li:first-child {
      border: 0;
    }

    li {
      padding: 8px 0;
      border-top: 1px solid #E6EAEE;
      &:first-child,
      &:nth-child(2) {
        border: 0;
      }
    }

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .price {
    font-family: 'Acumin Regular';
    font-size: 16px;
    line-height: 20px;
    color: #354052;
    margin-top: 0;
    position: relative;
  }

  .item-row {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .item-col {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 10px 10px 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -ms-flex-negative: 3;
    flex-shrink: 3;
    margin-right: auto;
    min-width: 0;

    &.item-col-title {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
      flex-basis: 0;
      flex-grow: 3;
    }
    &.item-col-sm {
      max-width: 220px;
    }
    &.item-col-xl {
      max-width: 210px;
    }
  }

  .token-data {
    padding-left: 0 !important;
  }

  .token-key {
    width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .copy-key {
    display: none;
    padding: 12px;
    color: var(--lp-colors-medium-blue-600) !important;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    left: 15px;
    bottom: -8px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    background-color: #fff;
    border: 1px solid #d1d3d7;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    width: auto;
    letter-spacing: 0.35px;
    cursor: pointer;

    &.active {
      display: block;
    }
  }

  .order-id {
    font-size: 16px;
    padding-top: 6px;
    font-weight: bold;
  }

  .external-icon {
    width: 15px;
    height: 15px;
  }

  .no-overflow {
    overflow: hidden;
  }

  .item-actions-block {
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 24px;
    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    border-radius: 3px;
    z-index: 1;
    display: none;

    &.active {
      display: block;
      min-width: 238px;
      max-width: 450px;
    }
  }

  .item-actions-list {
    list-style: none;
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }

  .item-actions-li {
    padding: 3px 0;
    border: 0;
    margin: 0;
    cursor: auto;
  }

  .item-actions-dropdown {
    position: absolute;
    right: 0;
    width: 15px;
    top: 0;
    cursor: pointer;
  }

  .api-keys {
    cursor: auto;
  }

  .modal-copy-btn {
    background-color: #fff;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .dots-icon {
    display: block;
  }

  .arrow-right {
    display: none;
    transform: rotate(270deg);
  }

  .help-wheel {
    width: 18px;
    height: 18px;
  }

  .platform-link {
    color: var(--lp-colors-medium-blue-600);
    font-weight: 300;
    text-decoration: none;
  }

  .form-submit-container {
    display: none;
  }

  @media (max-width: 1400px) {
    .item-col {
      padding-right: 0;
    }

    .price {
      font-size: 13px;
    }

    .order-id {
      font-size: 13px;
    }
  }

  @media (max-width: 768px) {
    .arrow-right {
      display: block;
    }

    .dots-icon {
      display: none;
    }

    .item-actions-dropdown {
      top: 30px;
      width: 30px;
    }

    .item-list {
      .item-list-header {
        display: none;
      }
      .item-col {
        flex-basis: 100%;
      }
      .item-col-sm {
        position: absolute;
        right: 0;
      }
      .hide-m-b {
        display: none;
      }
    }
  }

  @media (max-width: 570px) {
    .d-none-copy {
      display: none !important;
    }

    .copy-key {
      display: none;
      &.active {
        display: none;
      }
    }

    .token-key {
      max-width: 200px;
    }

    .arrow-right {
      display: none;
    }

    .form-submit-container {
      display: block;
      width: 100%;
      padding: 24px 26px;
      background-color: #f9f9f9;
      border: 1px solid #dfe3e9;
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 1111;
    }

    .mobile-copy-btn {
      cursor: pointer;
      padding: 13px 40px 10px;
      background-color: var(--lp-colors-medium-blue-600);
      color: #fff;
      border: 1px solid var(--lp-colors-medium-blue-600);
      margin-bottom: 5px;
      box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
      letter-spacing: 0.35px;
      width: 100%;
    }

    .installation-guides {
      padding-bottom: 150px;
    }
  }
}