.chart-wrapper {
  font-family: 'Acumin Pro', Arial, sans-serif;

  .col-sm-7 {
    min-height: 120px;
  }

  @media (min-width:854px) and (max-width: 922px) {
    .col-sm-7 {
      min-height: 140px;
    }
  }

  @media (min-width:1047px) and (max-width: 1094px) {
    .col-sm-7 {
      min-height: 120px;
    }
  }

  @media (min-width:1094px) and (max-width: 1162px) {
    .col-sm-7 {
      min-height: 140px;
    }
  }

  @media (min-width:1200px) and (max-width: 1239px) {
    .col-sm-7 {
      min-height: 140px;
    }
  }

  .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0;
    color: #354052;
  }

  .transaction-sum {
    padding: 8px 0;
    font-weight: normal;
    line-height: 1.2;
    font-family: inherit;
    color: inherit;
    font-size: 40px;
    margin: 0 0 7.5px;
  }

  .legnds {
    span {
      position: relative;
      display: inline-block;
      margin-right: 0;

      &:before {
        top: 1px;
        left: -10px;
        content: '';
        width: 4px;
        height: 16px;
        position: absolute;
      }
    }

    .today:before {
      background-color: #27cbcc;;
    }
  }

  .lp-color-black {
    color: #000;
  }
}
