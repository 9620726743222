.calendar-wrapper {
  .is-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .is-mobile {
      display: block;
      left: 20px;
    }
  }
}

.title-search-block {
  position: relative;
  max-width: 100%;
  margin: 0 auto;

  .items-search {
    right: 0;

    .input-group {
      position: relative;
    }

    .calendar-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      font-size: 12px;
    }

    .form-inline {
      &:last-child {
        .form-control {
          padding-left: 33px;
          border-radius: 3px;
        }
      }
      @media (min-width: 576px) {
        .form-control {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .form-control {
      padding: 18px 30px 16px 12px;
      font-family: "Acumin Pro", Arial, sans-serif;
      font-size: 15px;
    }

    .date-selection {
      position: relative;
    }

    .icon-wrapper {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .btn-date {
      display: block;
      padding-top: 3px;
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    .items-search {
      .form-control {
        width: 32px;
        padding-left: 0 !important;
        opacity: 0;
      }
    }
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 38px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #DCE3EB;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220,227,235,.56);
  -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220,227,235,.56);
  -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220,227,235,.56);
  box-sizing: border-box;
}

.input-group > .form-control {
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  width: 209px;
}

.daterangepicker {
  padding: 66px 32px 40px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 100%;
  max-width: 570px;
  box-sizing: border-box;

  &.show-ranges.ltr .drp-calendar.left {
    border: 0;
  }

  td.end-date, td.start-date {
    position: relative;
    z-index: 9999999;
    border-radius: 0;
  }

  td.end-date {
    top: 0;
    left: -1px;
  }

  td.active {
    background-color: #017f7f;

    &:hover {
      background-color: #017f7f;
    }

    &.start-date,
    &.start-date:hover {
      background-color: #ebf4f8;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    &.end-date,
    &.end-date:hover {
      background-color: #ebf4f8;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }

  td.end-date:before,
  td.start-date:before {
    position: absolute;
    content: '';
    background-color: #017f7f;
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 41px;
    top: 0;
    border-radius: 50%;
    z-index: -1;
  }

  td.start-date:before {
    left: 0;
  }

  td.end-date:before {
    right: 0;
  }

  td.available:hover,
  th.available:hover {
    background-color: #017f7f;
    border-radius: 50%;
  }

  td.start-date.end-date {
    border-radius: 50%;
  }

  td.in-range:hover,
  td.available:hover {
    background-color: #e2f1f1;
  }

  &:before,
  &:after {
    content: none;
    display: none;
  }
  .drp-calendar.right {
    th.month,
    .calendar-table thead tr:first-child th:first-child,
    .calendar-table thead tr:last-child,
    .calendar-table tbody {
      display: none;
    }
  }

  .calendar-table {
    .prev,
    .next {
      position: absolute;
      top: 28px;
      width: 44px;
      height: 44px;
      padding: 0;
      box-sizing: border-box;
      &:hover {
        background-color: #017f7f;
        color: #fff;
      }
    }

    .next {
      right: 44px;
    }

    .prev {
      right: 93px;
    }

    .prev span,
    .next span {
      border: 0;
      border: 1px #DCE3EB solid;
      width: 42px;
      height: 42px;
      display: block;
      border-radius: 50%;
      position: relative;
      box-sizing: border-box;
      transform: none;
      &:hover {
        background-color: #017f7f;
        color: #fff;
      }
    }

    .next:hover span,
    .prev:hover span {
      border: 0;
    }

    .prev:after,
    .next:after {
      position: absolute;
      left: 11px;
      width: 20px;
      font-size: 12px;
      top: 9px;
    }

    .next:after {
      content: ">";
      font-family: 'themify';
    }

    .prev:after {
      content: "<";
      font-family: 'themify';
    }

    th {
      font-size: 12px;
      font-weight: normal;
      color: #6c7488;

      &.month {
        font-size: 18px;
        color: #000;
        text-align: left;
        height: 39px;
      }
    }

    td {
      padding: 9px 12px 6px 12px;
      font-size: 14px;
      width: 40px;
      height: 40px;
      max-width: 40px;
      max-height: 41px;
      order: 0;
      box-sizing: border-box;
    }

    table.table-condensed {
      width: auto;
      margin: 0 auto;
    }
  }

  th.month {
    width: 167px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 38px;
  }
  select.monthselect {
    /*position: absolute;*/
    left: 38px;
    top: 35px;
    border: 0;
    width: 134px;
    overflow: hidden;
  }
  select.yearselect {
    position: relative;
    left: -28px;
    top: 0;
    border: 0;
    width: 134px;
    overflow: hidden;
  }
  .drp-calendar.left {
    padding: 12px 0 8px 8px;
  }
  .show-calendar .ranges {
    margin-top: 26px;
  }
  .ranges li {
    font-family: 'Acumin Regular';
    font-size: 14px;
    padding: 19px 12px 7px;
    cursor: pointer;

    &:hover, &.active {
      background-color: #fff;
      color: #017f7f;
    }
  }

  select {
    &.yearselect option,
    &.yearselect,
    &.monthselect,
    &.monthselect option {
      height: 30px;
      font-size: 19px;
    }

    &.yearselect,
    &.monthselect {
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &:focus {
      outline: none;
    }
  }

  .ranges ul {
    width: 166px;
  }
  .preview-email {
    background-color: #fcfcff;
    padding: 30px 10px;
  }

  td.end-date::before,
  td.start-date::before,
  td.end-date:before,
  td.start-date:before,
  .calendar-table td {
    @media (max-width: 768px) {
      width: 70px;
      height: 70px;
      max-width: 70px;
      min-width: 70px;
      max-height: 70px;
      min-height: 70px;
    }
    @media (max-width: 570px) {
      width: 62px;
      height: 62px;
      max-width: 62px;
      min-width: 62px;
      max-height: 62px;
      min-height: 62px;
    }
    @media (max-width: 480px) {
      width: 50px;
      height: 50px;
      max-width: 50px;
      min-width: 50px;
      max-height: 50px;
      min-height: 50px;
    }
    @media (max-width: 380px) {
      width: 46px;
      height: 46px;
      max-width: 46px;
      min-width: 46px;
      max-height: 46px;
      min-height: 46px;
    }
  }

  @media (max-width: 768px) {
    left: 0 !important;
    max-width: 100%;
    border: 0;
    padding: 12px 0 0 0;
    height: 91%;
    margin-top: 20px;

    &.ltr .drp-calendar {
      float: none;
    }

    .ranges {
      display: none;
    }

    .drp-calendar {
      max-width: 100%;
    }

    .calendar-table {
      td.off {
        color: #ccc;
      }
    }

    .drp-calendar.left .calendar-table thead tr:last-child th {
      padding-top: 45px;
    }

    th.month {
      position: relative;
      top: 0;
      vertical-align: middle;
      left: -49px;
    }
  }
}

.calendar-header {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  transition: all 0.5s ease-in;
  border-bottom: 1px solid #e7e7e7;
  padding: 20px 12px 12px 5px;
  z-index: 999;
  display: flex;
  box-sizing: border-box;

  .modal-title {
    font-size: 19px;
    line-height: 28px;
    color: #354052;
    margin: 0;
    font-family: 'Acumin Regular', Arial, sans-serif;
    font-weight: 400;
  }

  .save {
    color: var(--lp-colors-medium-blue-600);
    font-size: 15px;
    line-height: 28px;
    text-decoration: none;
    outline: none;
    position: relative;
  }

  .back {
    color: #6C7488;
    text-decoration: none;
    outline: none;
    position: relative;
    margin-right: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: 100%;
  }
}


