@font-face {
  font-family: 'Acumin Pro';
  src: url('assets/fonts/acumin-pro-light.eot');
  src: url('assets/fonts/acumin-pro-light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/acumin-pro-light.woff2') format('woff2'),
  url('assets/fonts/acumin-pro-light.woff') format('woff'),
  url('assets/fonts/acumin-pro-light.ttf') format('truetype');
}
@font-face {
  font-family: 'Acumin Regular';
  src: url('assets/fonts/Acumin-RPro.eot');
  src: url('assets/fonts/Acumin-RPro.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Acumin-RPro.woff2') format('woff2'),
  url('assets/fonts/Acumin-RPro.woff') format('woff'),
  url('assets/fonts/Acumin-RPro.ttf') format('truetype');
}

.App {
  padding-left: 240px;
  position: relative;
  width: 100%;
  min-height: 100vh;

  &.no-sidebar {
    padding-left: 0;
  }

  @media (max-width: 960px) {
    padding-left: 0;
  }

  .spinner-wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
}