@import 'col.css';
@import 'marginPadding.css';

:root {
  --lp-colors-medium-blue-600: #0016D1;
  --lp-colors-medium-blue-800: #000E8F;
  --lp-colors-turquoise-600: #43E3FF;
}

* {
  box-sizing: border-box;
  font-family: "Acumin Pro", Arial, sans-serif;
}

body {
  margin: 0;
}

b {
  color: #000000;
  font-family: 'Acumin Regular';
  font-weight: 700;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-right {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.lp-box {
  background-color: #fff;
  padding: 31px 29px 28px;
  border: 1px #e7e7e7 solid;
  -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  margin-bottom: 20px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .lp-box {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
}
