.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 16px !important;
}

.pt-4 {
  padding-top: 24px !important;
}

.pr-3 {
  padding-right: 16px !important;
}

.pl-3 {
  padding-left: 16px !important;
}
.pl-5 {
  padding-left: 42px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 4px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.ml-4 {
  margin-left: 24px !important;
}
.ml-5 {
  margin-left: 28px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mr-3 {
  margin-right: 16px !important;
}

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 16px !important;
  }
  .pt-sm-5 {
    padding-top: 48px !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .pr-sm-2 {
    padding-right: 8px !important;
  }
  .pl-sm-2{
    padding-left: 8px !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
}

@media (min-width: 769px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pt-md-1 {
    padding-top: 4px !important;
  }

  .pt-md-2 {
    padding-top: 11.2px !important;
  }

  .pt-md-3 {
    padding-top: 16px !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .ml-md-2 {
    margin-left: 8px !important;
  }

  .ml-md-3 {
    margin-left: 16px !important;
  }

  .mb-md-3 {
    margin-bottom: 16px !important;
  }
}

@media (min-width: 1200px) {
  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pt-xl-1 {
    padding-top: 4px;
  }

  .text-xl-right {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .pl-m {
    padding-left: 50px;
  }
}

@media (max-width: 570px) {
  .pl-m {
    padding-left: 50px;
  }
}