.refund-list {
  padding: 30px 22px 10px 22px;

  .card {
    background-color: transparent;
    margin-bottom: 10px;
    border-radius: 0;
    border: none;
    padding: 0 7px;
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    background-clip: border-box;
  }

  .item-list {
    list-style: none;
    margin: 0;
    margin-bottom: 0;
    line-height: 22.4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 0;
  }

  .item-col-amount {
    @media (max-width: 1400px) {
      max-width: 70px;
    }
  }

  .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    padding: 8px 0 8px;
    border-top: 1px solid #E6E6EAEE;
    &:first-child,
    &:nth-child(2) {
      border: 0;
    }
  }

  .item-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .item-list-header {
    .item-col {
      padding: 4px 10px 6px 0;
      span {
        font-size: 15px;
        color: #6C7488;
      }
    }
  }

  .item-col {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 10px 10px 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -ms-flex-negative: 3;
    flex-shrink: 3;
    margin-right: auto;
    min-width: 0;
    height: 31px;
    box-sizing: border-box;
    &.item-col-title {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 3;
      -ms-flex-positive: 3;
      flex-grow: 3;
    }
    &.item-col-cxl {
      max-width: 180px;
    }
    &.item-col-lgr {
      max-width: 250px;
    }
    &.fixed {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    &.item-col-actions-dropdown {
      padding-right: 0;
      width: 15px;
    }
    @media (max-width: 1400px) {
      padding-right: 0;
    }
  }

  .media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: center;
  }

  .feature-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }

  .wicons {
    border: 1px #989FB2 solid;
    width: 36px;
    height: 36px;
    min-width: 36px;
  }

  .order-id {
    font-size: 16px;
    font-weight: bold;
  }

  .customer-email {
    word-break: break-all;
  }

  .time-span {
    font-family: inherit;
  }

  .active-item {
    .item-col {
      height: 100%;
    }
  }
  .price {
    margin: 0;
    font-family: 'Acumin Regular';
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
  .icon-wrapper {
    cursor: pointer;
  }
  .item-actions-dropdown {
    position: relative;
    display: inline-block;
    font-size: 17.6px;
    top: 5px;
  }
  .item-actions-block {
    display: none;
    line-height: 30px;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    top: 0;
    right: 24px;
    padding: 15px 20px;
    border: 1px #e7e7e7 solid;
    -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    border-radius: 3px;
    text-align: left;
    z-index: 1;
    &.active {
      display: block;
      min-width: 238px;
      max-width: 450px;
    }
    .item-actions-list {
      list-style: none;
      white-space: nowrap;
      padding: 0;
      li {
        padding: 3px 0;
        margin: 0;
        &:first-child {
          border: 0;
        }
        .item-actions-list-link {
          color: #6C7488;
          display: block;
          font-size: 15px;
          line-height: 24px;
          font-family: "Acumin Pro", Arial, sans-serif;
        }
      }
    }
  }
  .d-none {
    display: none;
  }
  @media (min-width: 768px) {
    .d-md-inline {
      display: inline;
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 0 75px 0;

    .item-list-header {
      display: none;
    }

    .item {
      font-size: 14px;
      margin-bottom: 3px;

      .item-row-reset-m {
        max-width: 321px;
        min-width: 0;
        flex-direction: column;
      }
    }

    .item-list > li.item {
      padding: 16px 0;
    }

    .order-id,
    .price {
      font-size: 16px;
    }

    .price {
      padding-left: 50px;
      background-color: #fff;
    }

    .item-col-order {
      order: -2;
    }

    .item-col.item-col-status {
      order: -2;
      margin: 0;
      max-width: 100%;
      width: 100%;
    }

    .item-col-amount {
      order: -1;
      width: auto;
      max-width: 100%;
    }

    .item-col {
      padding: 0;
    }

    .item-list .item {
      padding: 0;
    }
    .arrow-right-icon {
      width: 25px;
      height: 25px;
      fill: #9ba8b5;
      transform: rotate(270deg);
    }

    .item-col-actions-dropdown {
      border: none;
      padding-bottom: 0;
      order: -3;
      flex-basis: 40px;
      padding-right: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  @media (max-width: 380px) {
    .order-id,
    .price {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) {
    padding: 18px 60px 25px 60px;
  }
  @media (max-width: 1400px) {
    .price {
      font-size: 13px;
    }
  }
}