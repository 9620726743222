.balance-page {
  .content {
    padding: 30px 22px 75px;
  }

  .bank-icon {
    width: 15px;
    height: 15px;
  }

  .media {
    display: flex;
    align-items: flex-start;
  }

  .wicons {
    border: 1px solid #989FB2;
    width: 36px;
    height: 36px;
  }

  .feature-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }

  h2 {
    font-family: 'Acumin Regular', Arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    font-family: 'Acumin Regular', Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #354052;
    margin-top: 0;
  }

  .amount {
    margin-bottom: 8px;
  }

  .lp-color-purple {
    color: var(--lp-colors-medium-blue-600);
  }

  .custom-ml-text {
    margin-left: 35px;
  }

  .amount-wrap {
    text-align: left;
  }

  .custom-mb-card {
    margin-bottom: -30px;
  }

  .row-p {
    padding-left: 67px;
    color: #6C7488;
    line-height: 24px;
    margin-top: 14px;
  }

  .custom-pt {
    padding-top: 5px;
  }

  @media (min-width: 1200px) {
    .lp-dashboard-top {
      padding: 33px 60px 30px;
    }

    .content {
      padding: 18px 60px 25px;
    }
  }

  @media (min-width: 768px) {
    .amount-wrap {
      text-align: right;
    }
  }

  @media (max-width: 768px) {
    .balance-f {
      padding-left: 46px;
    }

    .reset-lr {
      padding: 0 0 75px;
    }
  }
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}